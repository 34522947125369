import { ApiRequest, GetVerb, PostVerb, documentPlaceOrdersUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import _ from "lodash";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { VendorOrdersRecordType } from "@/lib/settings";
import { getGridData } from "@/store/searchResults.js";

export default {
  state: {
    documentPlaceOrders: [],
    productDocuments: [],
    documentPlaceOrderOrderNumbersNameSearchData: [],
    documentPlacedOrderReplyToSetting: [],
  },
  getters: {},
  mutations: {
    setDocumentPlaceOrderDataSimple(state, documentPlaceOrdersData) {
      state.documentPlaceOrders = documentPlaceOrdersData ?? [];
    },
    setProductDocuments(state, newProductDocuments) {
      state.productDocuments = _.cloneDeep(newProductDocuments);
    },
    clearDocumentPlaceOrders(state) {
      state.documentPlaceOrders = [];
    },
    clearDocumentPlaceOrderData(state) {
      state.documentPlaceOrders = [];
    },
    setDocumentPlaceOrderOrderNumbersNameSearchData(state, documentPlaceOrderOrderNumbersNameSearchData) {
      state.documentPlaceOrderOrderNumbersNameSearchData = documentPlaceOrderOrderNumbersNameSearchData ?? [];
    },
    setDocumentPlacedOrderReplyToSetting(state, documentPlacedOrderReplyToSetting) {
      state.documentPlacedOrderReplyToSetting = documentPlacedOrderReplyToSetting ?? [];
    },
  },
  actions: {
    async getDocumentPlaceOrdersData(
      context,
      {
        PlacingOrganizationRootId,
        PlacedToOrganizationRootId,
        OrganizationRootId,
        UserRootId,
        OrderNumber,
        OrderRootId,
        DocumentPlaceOrderRootId,
        PreviewParameters,
        IncludeMetadata,
        CanUseServerCache,
      }
    ) {
      return timeOperation(async () => {
        PlacingOrganizationRootId = Number(PlacingOrganizationRootId ?? 0);
        PlacedToOrganizationRootId = Number(PlacedToOrganizationRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        OrderNumber = String(OrderNumber ?? "");
        OrderRootId = Number(OrderRootId ?? 0);
        DocumentPlaceOrderRootId = Number(DocumentPlaceOrderRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        CanUseServerCache = CanUseServerCache ?? true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${documentPlaceOrdersUrl}?placingorganizationrootid=${PlacingOrganizationRootId}&placedtoorganizationrootid=${PlacedToOrganizationRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&orderrootid=${OrderRootId}&documentplaceorderrootid=${DocumentPlaceOrderRootId}&orderNumber=${OrderNumber}&previewparameters=${previewParametersJson}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&includemetadata=${IncludeMetadata}&canusecache=${CanUseServerCache}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setDocumentPlaceOrderDataSimple", data);
        return data;
      }, "documentPlaceOrders - getDocumentPlaceOrdersData");
    },
    async getDocumentPlaceOrdersAdvancedSearchData(
      context,
      {
        ProductRootId,
        SearchRootId,
        OrganizationRootId,
        UserRootId,
        PreviewParameters,
        IncludeMetadata,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        NoCache,
        CanUseServerCache }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        ExcludeBlobs = ExcludeBlobs ?? false;
        IncludeDataSourceFields = false;//IncludeDataSourceFields ?? true;
        IncludeFieldSettings = IncludeFieldSettings ?? true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? true;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? true;
        IncludeGridColumns = IncludeGridColumns ?? true;
        IncludeGroupColumns = IncludeGroupColumns ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${documentPlaceOrdersUrl
          }?productrootid=${ProductRootId
          }&searchrootid=${SearchRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&excludeblobs=${ExcludeBlobs
          }&includedatasourcefields=${IncludeDataSourceFields
          }&includefieldsettings=${IncludeFieldSettings
          }&includefieldnameregistry=${IncludeFieldNameRegistry
          }&includenamefieldregistry=${IncludeNameFieldRegistry
          }&includegridcolumns=${IncludeGridColumns
          }&includegroupcolumns=${IncludeGroupColumns
          }&includedebugdata=${IncludeDebugData
          }&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType: VendorOrdersRecordType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType: VendorOrdersRecordType, Data });
      }, "documentPlaceOrders - getDocumentPlaceOrdersAdvancedSearchData");
    },
    async getDocumentPlaceOrderOrderNumbersNameSearchData(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        SearchText,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        CanUseServerCache
      }
    ) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        SearchText = String(SearchText ?? "");
        ExcludeBlobs = ExcludeBlobs ?? false;
        IncludeDataSourceFields = false;//IncludeDataSourceFields ?? true;
        IncludeFieldSettings = IncludeFieldSettings ?? true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? true;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? true;
        IncludeGridColumns = IncludeGridColumns ?? true;
        IncludeGroupColumns = IncludeGroupColumns ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        const formattedUrl = `${documentPlaceOrdersUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&searchrootid=0&searchtext=${SearchText
          }&ordernumber=&timezone=${encodeURIComponent(getLocalTimezone())
          }&excludeblobs=${ExcludeBlobs
          }&includedatasourcefields=${IncludeDataSourceFields
          }&includefieldsettings=${IncludeFieldSettings
          }&includefieldnameregistry=${IncludeFieldNameRegistry
          }&includenamefieldregistry=${IncludeNameFieldRegistry
          }&includegridcolumns=${IncludeGridColumns
          }&includegroupcolumns=${IncludeGroupColumns
          }&includedebugdata=${IncludeDebugData
          }&searchonlyordernumbers=true&returnonlyordernumbers=true&canusecache=${CanUseServerCache}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setDocumentPlaceOrderOrderNumbersNameSearchData", data);
      }, "orders - getOrderNumbersNameSearchData");
    },
    async getDocumentPlaceOrderReplyToSetting(
      context,
      { ProductRootId, UserRootId, PlacingOrganizationRootId, PlacedToOrganizationRootId, DocumentPlaceOrderRootIds, UseCache = false }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        PlacingOrganizationRootId = Number(PlacingOrganizationRootId ?? 0);
        PlacedToOrganizationRootId = Number(PlacedToOrganizationRootId ?? 0);
        DocumentPlaceOrderRootIds = String(ProductRootId ?? "");
        const formattedUrl = `${documentPlaceOrdersUrl}/replytohistory?productrootid=${ProductRootId}&userrootid=${UserRootId}&placingorganizationrootid=${PlacingOrganizationRootId}&placedtoorganizationrootid=${PlacedToOrganizationRootId}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&documentplaceorderrootids=${DocumentPlaceOrderRootIds}`;
        const result = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (UseCache) {
          context.commit("setDocumentPlacedOrderReplyToSetting", result);
        }
        return result;
      }, "documentPlaceOrders - getDocumentPlaceOrderReplyToSetting");
    },
    async addDocumentPlaceOrder(context, documentPlaceOrder) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentPlaceOrdersUrl, Payload: documentPlaceOrder });
      }, "documentPlaceOrders - addDocumentPlaceOrder");
    },
  },
};
