import {ApiRequest, DeleteVerb, GetVerb, PostVerb, PutVerb, workflowNotificationsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { WorkflowNotificationsRecordType } from "@/lib/settings";
import { getGridData } from "@/store/searchResults.js";

export default {
  actions: {
    async getWorkflowNotificationsAdvancedSearchData(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        UserRootId,
        SearchRootId,
        PreviewParameters,
        IncludeMetadata,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        NoCache,
        CanUseServerCache
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        ExcludeBlobs = ExcludeBlobs ?? false;
        IncludeDataSourceFields = false;//IncludeDataSourceFields ?? true;
        IncludeFieldSettings = IncludeFieldSettings ?? true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? true;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? true;
        IncludeGridColumns = IncludeGridColumns ?? true;
        IncludeGroupColumns = IncludeGroupColumns ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${workflowNotificationsUrl
        }?productrootid=${ProductRootId
        }&organizationrootid=${OrganizationRootId
        }&userrootid=${UserRootId
        }&searchrootid=${SearchRootId
        }&previewparameters=${previewParametersJson
        }&timezone=${encodeURIComponent(getLocalTimezone())
        }&includemetadata=${IncludeMetadata
        }&excludeblobs=${ExcludeBlobs
        }&includedatasourcefields=${IncludeDataSourceFields
        }&includefieldsettings=${IncludeFieldSettings
        }&includefieldnameregistry=${IncludeFieldNameRegistry
        }&includenamefieldregistry=${IncludeNameFieldRegistry
        }&includegridcolumns=${IncludeGridColumns
        }&includegroupcolumns=${IncludeGroupColumns
        }&includedebugdata=${IncludeDebugData
        }&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType: WorkflowNotificationsRecordType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType: WorkflowNotificationsRecordType, Data });
      }, "workflowNotifications - getWorkflowNotificationsAdvancedSearchData");
    },
    async addWorkflowNotification(context, workflowNotification) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: workflowNotificationsUrl, Payload: workflowNotification});
      }, "workflowNotifications - addWorkflowNotification");
    },
    async updateWorkflowNotification(context, workflowNotification) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationsUrl}/${Number(workflowNotification?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowNotification});
      }, "workflowNotifications - updateWorkflowNotification");
    },
    async removeWorkflowNotification(context, workflowNotification) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationsUrl}/${Number(workflowNotification?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
      }, "workflowNotifications - removeWorkflowNotification");
    },
    async removeWorkflowNotifications(context, workflowNotifications) {
      return timeOperation(async () => {
        if (workflowNotifications?.length > 0) {
          let scrubbedWorkflowNotifications = [];
          workflowNotifications.forEach((workflowNotification) => {
            if (workflowNotification?.RootId > 0) {
              scrubbedWorkflowNotifications.push({
                RootId: Number(workflowNotification.WorkflowNotificationRootId > 0 ? workflowNotification.WorkflowNotificationRootId : workflowNotification.RootId),
                OrderNumber: String(workflowNotification.OrderNumber),
                ProductRootId: Number(workflowNotification.ProductRootId ?? 0),
              });
            }
          });
          const formattedUrl = `${workflowNotificationsUrl}/deletemultiple`;
          return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: formattedUrl, Payload: scrubbedWorkflowNotifications});
        }
      }, "workflowNotifications - removeWorkflowNotifications");
    },
  },
};
