import {ApiRequest, DeleteVerb, GetVerb, PostVerb, PutVerb, workflowRulesUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { WorkflowRulesRecordType } from "@/lib/settings";
import { getGridData } from "@/store/searchResults.js";

export default {
  actions: {
    async getWorkflowRulesAdvancedSearchData(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        UserRootId,
        SearchRootId,
        PreviewParameters,
        IncludeMetadata,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        NoCache,
        CanUseServerCache
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        ExcludeBlobs = ExcludeBlobs ?? false;
        IncludeDataSourceFields = false;//IncludeDataSourceFields ?? true;
        IncludeFieldSettings = IncludeFieldSettings ?? true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? true;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? true;
        IncludeGridColumns = IncludeGridColumns ?? true;
        IncludeGroupColumns = IncludeGroupColumns ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${workflowRulesUrl
        }?productrootid=${ProductRootId
        }&organizationrootid=${OrganizationRootId
        }&userrootid=${UserRootId
        }&searchrootid=${SearchRootId
        }&previewparameters=${previewParametersJson
        }&timezone=${encodeURIComponent(getLocalTimezone())
        }&includemetadata=${IncludeMetadata
        }&excludeblobs=${ExcludeBlobs
        }&includedatasourcefields=${IncludeDataSourceFields
        }&includefieldsettings=${IncludeFieldSettings
        }&includefieldnameregistry=${IncludeFieldNameRegistry
        }&includenamefieldregistry=${IncludeNameFieldRegistry
        }&includegridcolumns=${IncludeGridColumns
        }&includegroupcolumns=${IncludeGroupColumns
        }&includedebugdata=${IncludeDebugData
        }&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType: WorkflowRulesRecordType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType: WorkflowRulesRecordType, Data });
      }, "workflowRules - getWorkflowRulesAdvancedSearchData");
    },
    async addWorkflowRule(context, workflowRule) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: workflowRulesUrl, Payload: workflowRule});
      }, "workflowRules - addWorkflowRule");
    },
    async updateWorkflowRule(context, workflowRule) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRulesUrl}/${Number(workflowRule?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowRule});
      }, "workflowRules - updateWorkflowRule");
    },
    async removeWorkflowRule(context, workflowRule) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRulesUrl}/${Number(workflowRule?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
      }, "workflowRules - removeWorkflowRule");
    },
    async removeWorkflowRules(context, workflowRules) {
      return timeOperation(async () => {
        if (workflowRules?.length > 0) {
          let scrubbedWorkflowRules = [];
          workflowRules.forEach((workflowRule) => {
            if (workflowRule?.RootId > 0) {
              scrubbedWorkflowRules.push({
                RootId: Number(workflowRule.WorkflowRuleRootId > 0 ? workflowRule.WorkflowRuleRootId : workflowRule.RootId),
                OrderNumber: String(workflowRule.OrderNumber),
                ProductRootId: Number(workflowRule.ProductRootId ?? 0),
              });
            }
          });
          const formattedUrl = `${workflowRulesUrl}/deletemultiple`;
          return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: formattedUrl, Payload: scrubbedWorkflowRules});
        }
      }, "workflowRules - removeWorkflowRules");
    },
  },
};
