<!--suppress JSUnusedGlobalSymbols, JSUnfilteredForInLoop, JSUnresolvedVariable, JSCheckFunctionSignatures -->

<template>
  <div @dragenter.prevent @dragover.prevent @drop="handleDrop">
    <NewVersionMessage v-if="newVersionDetected" />
    <ContextMenuReplacement
      v-model:open="openContextMenu"
      v-model:close="closeContextMenu"
      :item-selected-function="contextMenuItemSelectedFunction"
      :before-open="beforeContextMenuOpenFunction"
      :target-element-top="contextMenuTargetElementTop"
      :target-element-left="contextMenuTargetElementLeft"
    />
    <div>
      <router-view :informationLoaded="productInformationLoaded && userInformationLoaded" />
    </div>
    <MessageOverlay
      :show="showLoadingMessage"
      @update:show="setShowLoadingMessage"
      :show-spinner="showSpinner"
      :message="loadingMessage"
      :fade-background="fadeBackground"
      :minimum-time-shown-seconds="loadingMessageMinimumTimeShownSeconds"
    />
    <FeedbackModal :show="showFeedbackModal" />
  </div>
</template>

<script>
import { computed, nextTick, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";

import MessageOverlay from "@/components/uIElements/MessageOverlay.vue";
import timeOperation from "@/utils/timeOperation";
import FeedbackModal from "@/components/modals/FeedbackModal.vue";
import ContextMenuReplacement from "@/components/syncfusionReplacementWrappers/ContextMenuReplacement.vue";
import NewVersionMessage from "@/components/NewVersionMessage.vue";

const VERSION_CHECK_TIMER = 600_000; // Ten Minutes
export default {
  name: "app",
  components: {
    MessageOverlay,
    FeedbackModal,
    ContextMenuReplacement,
    NewVersionMessage,
  },
  setup() {
    const store = useStore();
    //state
    const loggedInUser = computed(() => store.state.auth.loggedInUser);
    const loggedInUserRootId = computed(() => loggedInUser.value?.UserRootId || 0);
    const loggedInUserOrganizationRootId = computed(() => loggedInUser.value?.OrganizationRootId || 0);
    const showLoadingMessage = computed(() => store.state.load.showLoadingMessage);
    const loadingMessage = computed(() => store.state.load.loadingMessage);
    const loadingMessageMinimumTimeShownSeconds = computed(() => store.state.load.loadingMessageMinimumTimeShownSeconds);
    const showSpinner = computed(() => store.state.load.showSpinner);
    const fadeBackground = computed(() => store.state.load.fadeBackground);
    const cachedProductDataSourcesProductRootId = computed(() => store.state.prods.cachedProductDataSourcesProductRootId);
    const showFeedbackModal = computed(() => store.state.auth.showFeedbackModal);
    const allowFileDrop = computed(() => store.state.drop.allowFileDrop);
    const beforeContextMenuOpenFunction = computed(() => store.state.cm.beforeContextMenuOpenFunction);
    const contextMenuItemSelectedFunction = computed(() => store.state.cm.contextMenuItemSelectedFunction);
    const contextMenuTargetElementTop = computed(() => store.state.cm.contextMenuTargetElementTop);
    const contextMenuTargetElementLeft = computed(() => store.state.cm.contextMenuTargetElementLeft);
    const organizationDocumentTypes = computed(() => store.state.odt.organizationDocumentTypes);
    const organizationDocumentCategories = computed(() => store.state.odc.organizationDocumentCategories);
    //getters
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    //mutations
    const setLoadedSearchLocations = (value) => store.commit("setLoadedSearchLocations", value);
    const setLoadedDataSources = (value) => store.commit("setLoadedDataSources", value);
    const setShowLoadingMessage = (value) => store.commit("setShowLoadingMessage", value);
    const setContextMenuOpenFunction = (value) => store.commit("setContextMenuOpenFunction", value);
    const setCloseContextMenuFunction = (value) => store.commit("setCloseContextMenuFunction", value);
    //actions
    const loadOperation = async (Operation, Message, options) => await store.dispatch("loadOperation", { Operation, Message, Caller: "App", ...(options || {}) });
    const getSearchOperators = async () => await store.dispatch("getSearchOperators");
    const getUserLevelFormLayouts = async () =>
      await store.dispatch("getUserLevelFormLayouts", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        UserRootId: loggedInUser.value?.UserRootId || 0,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        FormLocationRootId: 0,
        InjectReferences: false,
        IncludeDrafts: false,
        IncludeMetadata: false,
        IncludeExtendedMetadata: false,
      });
    const getSearchLocations = async () => await store.dispatch("getSearchLocations");
    const getUsersData = async () =>
      await store.dispatch("getUsersData", {
        OrganizationRootId: loggedInUserOrganizationRootId.value || 0,
        UseCache: true,
      });
    const getOrganizationDataSources = async () =>
      await store.dispatch("getOrganizationDataSources", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        IncludeMetadata: true,
        IncludeExtendedMetadata: true,
        IncludeCustomFields: true,
        ExcludeBlobs: false,
        ValidateRecords: false,
        UseCache: false,
        CanUseServerCache: true,
      });
    const getOrganizationDataSourceTypes = async () =>
      await store.dispatch("getOrganizationDataSourceTypes", {
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        ProductRootId: getGlobalSelectedProductRootId.value,
      });
    const getOrganizationGroups = async () =>
      await store.dispatch("getOrganizationGroups", {
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        IncludeMetadata: false,
        IncludeMembership: false,
        IncludeAccessSettings: false,
      });
    const getOrganizationDocumentTypes = async () =>
      await store.dispatch("getOrganizationDocumentTypes", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        IncludeMetadata: true,
        IncludeOrganizationDocumentTypeFields: true,
        IncludeOrganizationDocumentTypeCategories: true,
        IncludeOrganizationDocumentTypeTitles: true,
        IncludeSystemLevelMetadata: false,
        IncludeProductLevelMetadata: false,
        IncludeOrganizationLevelMetadata: true,
        UseCache: true,
      });
    const getOrganizationVendorsList = async () =>
      await store.dispatch("getOrganizationVendorsList", {
        receiveOrdersFromOrganizationRootId: loggedInUserOrganizationRootId.value,
      });
    const getProductFormLocations = async () => await store.dispatch("getProductFormLocations", { ProductRootId: getGlobalSelectedProductRootId.value });
    const getProductDocumentTemplateTypes = async () => await store.dispatch("getProductDocumentTemplateTypes", { ProductRootId: getGlobalSelectedProductRootId.value });
    const getOrganizationCustomFields = async () => {
      await store.dispatch("getOrganizationCustomFields", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        UseCache: true,
        CanUseServerCache: true,
      });
    };
    const getOrganizationCustomUserFields = async () =>
      await store.dispatch("getOrganizationCustomUserFields", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        UseCache: true,
        CanUseServerCache: true,
      });
    const getDataSourceTypes = async () => await store.dispatch("getDataSourceTypes");
    const getDocumentPlaceOrderReplyToSetting = async () =>
      await store.dispatch("getDocumentPlaceOrderReplyToSetting", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        UserRootId: loggedInUser.value?.UserRootId || 0,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        PlacingOrganizationRootId: loggedInUserOrganizationRootId.value,
        PlacedToOrganizationRootId: 0,
        UseCache: true,
      });
    const getOrganizationVendors = async () =>
      await store.dispatch("getOrganizationVendors", {
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        IncludePossible: false,
      });
    const getOrganizationDocumentCategories = async () =>
      await store.dispatch("getOrganizationDocumentCategories", {
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        ProductRootId: getGlobalSelectedProductRootId.value,
        IncludeMetadata: true,
        IncludeOrganizationDocumentTypes: true,
        IncludeSystemLevelMetadata: true,
        IncludeProductLevelMetadata: true,
        IncludeOrganizationLevelMetadata: true,
        UseCache: true,
      });

    watch(
      loggedInUserRootId,
      async (is, was) => {
        userInformationLoaded.value = false;
        await timeOperation(async () => {
          if (is === was) {
            return;
          }

          const promises = [];

          if (is > 0 && getGlobalSelectedProductRootId.value > 0) {
            promises.push(
              loadOperation(async () => {
                await Promise.allSettled([
                  getSearchOperators(),
                  getUserLevelFormLayouts(),
                  getOrganizationDataSources(),
                  getOrganizationDataSourceTypes(),
                  getOrganizationCustomFields(),
                  getOrganizationCustomUserFields(),
                  getDocumentPlaceOrderReplyToSetting(),
                  getProductFormLocations(),
                ]).catch((error) => console.error(error));
              }, "Loading Organization Settings")
            );
          }

          if (is > 0) {
            setLoadedSearchLocations(false);
            promises.push(
              loadOperation(async () => {
                await Promise.allSettled([
                  getDataSourceTypes(),
                  getSearchOperators(),
                  (async () => {
                    await getSearchLocations();
                    setLoadedSearchLocations(true);
                  })(),
                  getUsersData(),
                  getOrganizationGroups(),
                  getOrganizationVendorsList(),
                  getOrganizationVendors(),
                  getProductDocumentTemplateTypes(),
                ]).catch((error) => console.error(error));
              }, "Loading Organization Data")
            );
          }

          await Promise.allSettled(promises).catch((error) => console.error(error));
        }, "App - watch - loggedInUserRootId");

        nextTick(() => {
          userInformationLoaded.value = true;
        });
      },
      {
        deep: true,
      }
    );
    watch(getGlobalSelectedProductRootId, async (is) => {
      productInformationLoaded.value = false;
      await timeOperation(async () => {
        const promises = [];

        if (cachedProductDataSourcesProductRootId.value !== is) {
          promises.push(
            loadOperation(async () => {
              setLoadedDataSources(false);
              await Promise.allSettled([(async () => {})(), getProductFormLocations()]).catch((error) => console.error(error));
              setLoadedDataSources(true);
            }, "Loading Product")
          );
        }
        if (loggedInUser.value?.UserRootId > 0 && is > 0) {
          promises.push(
            await loadOperation(async () => {
              await Promise.allSettled([
                getSearchOperators(),
                getUserLevelFormLayouts(),
                getOrganizationDataSources(),
                getOrganizationDataSourceTypes(),
                getOrganizationCustomFields(),
                getOrganizationCustomUserFields(),
                getDocumentPlaceOrderReplyToSetting(),
                getProductFormLocations(),
                getProductDocumentTemplateTypes(),
              ]).catch((error) => console.error(error));
            }, "Loading Organization Settings")
          );
        }

        await Promise.allSettled(promises).catch((error) => console.error(error));
      }, "App - watch - getGlobalSelectedProductRootId");
      nextTick( () => {
        productInformationLoaded.value = true;
        setTimeout(async() => {
          await loadOperation(async () => {
            await Promise.allSettled([
              getOrganizationDocumentCategories(),
              getOrganizationDocumentTypes(),
            ]).catch((error) => console.error(error));
          }, "Loading Organization Document Types", { HiddenLoading: true });
        }, 20_000);
      });
    });

    function handleDrop(event) {
      if (!allowFileDrop.value) {
        event.preventDefault();
        event.stopPropagation();
      }
    }

    const userInformationLoaded = ref(true);
    const productInformationLoaded = ref(true);
    const openContextMenu = ref(() => {});
    const closeContextMenu = ref(() => {});
    const currentVersionInProduction = ref("");
    const lastVersionLoaded = ref("");

    const newVersionDetected = computed(() => lastVersionLoaded.value !== currentVersionInProduction.value);

    watch(
      closeContextMenu,
      (is) => {
        setCloseContextMenuFunction(is);
      },
      {
        deep: true,
      }
    );

    watch(
      openContextMenu,
      (is) => {
        setContextMenuOpenFunction(is);
      },
      {
        deep: true,
      }
    );

    function fetchCurrentVersion() {
      setTimeout(() => {
        // TODO: set the currentVersionInProduction to what the server has
        fetchCurrentVersion();
      }, VERSION_CHECK_TIMER);
    }

    onMounted(async () => {
      await nextTick();
      // TODO: get the version and set lastVersionLoaded from the server
      currentVersionInProduction.value = lastVersionLoaded.value;
      fetchCurrentVersion();
    });

    return {
      organizationDocumentCategories,
      organizationDocumentTypes,
      productInformationLoaded,
      userInformationLoaded,
      showLoadingMessage,
      loadingMessage,
      loadingMessageMinimumTimeShownSeconds,
      showSpinner,
      fadeBackground,
      showFeedbackModal,
      openContextMenu,
      contextMenuTargetElementLeft,
      contextMenuTargetElementTop,
      beforeContextMenuOpenFunction,
      closeContextMenu,
      contextMenuItemSelectedFunction,
      newVersionDetected,
      lastVersionLoaded,
      currentVersionInProduction,

      setShowLoadingMessage,
      getUserLevelFormLayouts,
      handleDrop,
    };
  },
};
</script>

<style lang="scss">
// TODO: remove these styles eventually
@import "assets/styles/common/syncfusion-styles/material.scss";
@import "assets/styles/main";
@import "./assets/styles/stylesheet.css";
@import "../node_modules/v-tooltip/dist/v-tooltip.css";
@import "../node_modules/material-design-icons-iconfont/dist/material-design-icons.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-layouts/styles/material.css";

.v-popper__popper {
  z-index: 1000001 !important;
}
</style>
