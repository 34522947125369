import { ApiRequest, GetVerb, PutVerb, attachmentsUrl, statesUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { DocumentsRecordType } from "@/lib/settings";
import { getGridData } from "@/store/searchResults.js";

export default {
  state: {
    attachmentsCachedOrderRootId: 0,
    statesAndCounties: [],
    selectedForTaggingAttachmentRootId: 0,
  },
  getters: {},
  mutations: {
    setAttachmentsCachedOrderRootId(state, rootId) {
      state.attachmentsCachedOrderRootId = rootId;
    },
    setSelectedForTaggingAttachmentRootId(state, rootId) {
      state.selectedForTaggingAttachmentRootId = rootId;
    },
    setStatesAndCounties(state, stateAndCountyData) {
      state.statesAndCounties = stateAndCountyData?.sort((st1, st2) => (st1.Name > st2.Name ? 1 : -1)) ?? [];
    },
  },
  actions: {
    async getAttachmentData(
      context,
      {
        OrderRootId,
        DocumentPlaceOrderRootId,
        ProductRootId,
        OrganizationRootId,
        UserRootId,
        SearchRootId,
        SearchText,
        ContentDispositionPrefix,
        IncludeMetadata,
        IncludeParties,
        IncludeDocumentTypeFields,
        IncludePresignedUrl,
        PreviewParameters,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        NoCache,
        CanUseServerCache,
        UseCache,
      }
    ) {
      return timeOperation(async () => {
        OrderRootId = Number(OrderRootId ?? 0);
        UseCache = UseCache ?? false;
        if (UseCache && context.state.attachmentsCachedOrderRootId === OrderRootId) {
          return;
        }
        DocumentPlaceOrderRootId = Number(DocumentPlaceOrderRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        SearchText = String(SearchText ?? "");
        ContentDispositionPrefix = String(ContentDispositionPrefix ?? "");
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeParties = IncludeParties ?? false;
        IncludeDocumentTypeFields = IncludeDocumentTypeFields ?? false;
        IncludePresignedUrl = IncludePresignedUrl ?? false;
        ExcludeBlobs = ExcludeBlobs ?? false;
        IncludeDataSourceFields = false; //IncludeDataSourceFields ?? true;
        IncludeFieldSettings = IncludeFieldSettings ?? true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? true;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? true;
        IncludeGridColumns = IncludeGridColumns ?? true;
        IncludeGroupColumns = IncludeGroupColumns ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        context.commit("setAttachmentsCachedOrderRootId", OrderRootId);
        const formattedUrl = `${attachmentsUrl}?orderrootid=${OrderRootId}&documentplaceorderrootid=${DocumentPlaceOrderRootId}&productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&searchrootid=${SearchRootId}&includemetadata=${IncludeMetadata}&searchtext=${SearchText}&previewparameters=${previewParametersJson}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&contentdispositionprefix=${ContentDispositionPrefix}&includedocumenttypefields=${IncludeDocumentTypeFields}&includeparties=${IncludeParties}&includepresignedurl=${IncludePresignedUrl}&excludeblobs=${ExcludeBlobs}&includedatasourcefields=${IncludeDataSourceFields}&includefieldsettings=${IncludeFieldSettings}&includefieldnameregistry=${IncludeFieldNameRegistry}&includenamefieldregistry=${IncludeNameFieldRegistry}&includegridcolumns=${IncludeGridColumns}&includegroupcolumns=${IncludeGroupColumns}&includedebugdata=${IncludeDebugData}&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType: DocumentsRecordType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType: DocumentsRecordType, Data });
      }, "attachments - getAttachmentData");
    },
    async getSingleAttachmentData(
      context,
      {
        RootId,
        UserRootId,
        ProductRootId,
        SearchRootId,
        ContentDispositionPrefix,
        ReturnOnlyOrderNumbers,
        IncludeMetadata,
        IncludeDocumentTypeFields,
        IncludePresignedUrl,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        NoCache,
        CanUseServerCache,
      }
    ) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        ContentDispositionPrefix = String(ContentDispositionPrefix ?? "");
        ReturnOnlyOrderNumbers = ReturnOnlyOrderNumbers ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeDocumentTypeFields = IncludeDocumentTypeFields ?? false;
        IncludePresignedUrl = IncludePresignedUrl ?? false;
        ExcludeBlobs = ExcludeBlobs ?? false;
        IncludeDataSourceFields = false; //IncludeDataSourceFields ?? true;
        IncludeFieldSettings = IncludeFieldSettings ?? true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? true;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? true;
        IncludeGridColumns = IncludeGridColumns ?? true;
        IncludeGroupColumns = IncludeGroupColumns ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        const formattedUrl = `${attachmentsUrl}/${RootId}?userrootid=${UserRootId}&productrootid=${ProductRootId}&searchrootid=${SearchRootId}&searchtext=&ordernumber=&returnonlyordernumbers=${ReturnOnlyOrderNumbers}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&contentdispositionprefix=${ContentDispositionPrefix}&includemetadata=${IncludeMetadata}&includedocumenttypefields=${IncludeDocumentTypeFields}&includepresignedurl=${IncludePresignedUrl}&excludeblobs=${ExcludeBlobs}&includedatasourcefields=${IncludeDataSourceFields}&includefieldsettings=${IncludeFieldSettings}&includefieldnameregistry=${IncludeFieldNameRegistry}&includenamefieldregistry=${IncludeNameFieldRegistry}&includegridcolumns=${IncludeGridColumns}&includegroupcolumns=${IncludeGroupColumns}&includedebugdata=${IncludeDebugData}&canusecache=${CanUseServerCache}`;
        const updatedAttachments = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        const updatedAttachment = updatedAttachments?.data?.length > 0 ? updatedAttachments.data[0] : null;
        if (!NoCache) {
          context.commit("_updateAttachment", updatedAttachment);
        }
        return updatedAttachment;
      }, "attachments - getSingleAttachmentData");
    },
    async getAttachmentDataAndColumns(context, { ProductRootId, SearchRootId }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        const formattedUrl = `${attachmentsUrl}?orderrootid=-1&productrootid=${ProductRootId}&searchrootid=${SearchRootId}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        context.commit("setSearchResultsData", { ViewType: DocumentsRecordType, Data });
        return Data;
      }, "attachments - getAttachmentDataAndColumns");
    },
    async updateAttachment(context, attachment) {
      await timeOperation(async () => {
        const formattedUrl = `${attachmentsUrl}/${Number(attachment?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: attachment });
        context.commit("_updateAttachment", data);
      }, "attachments - updateAttachment");
    },
    async renameAttachment(context, attachment) {
      return timeOperation(async () => {
        const formattedUrl = `${attachmentsUrl}/${Number(attachment?.RootId ?? 0)}/rename`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: attachment });
      }, "attachments - renameAttachment");
    },
    async updateAttachmentNotes(context, attachment) {
      await timeOperation(async () => {
        const formattedUrl = `${attachmentsUrl}/updatenotes`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: attachment });
        context.commit("_updateAttachment", data);
      }, "attachments - updateAttachmentNotes");
    },
    async getStates(context, useCache) {
      await timeOperation(async () => {
        if (!useCache && context.state.statesAndCounties.length > 0) {
          const formattedUrl = `${statesUrl}?includemetadata=false`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setStatesAndCounties", data);
        }
      }, "attachments - getStates");
    },
  },
};
